// Import our custom CSS
import '../scss/theme.scss'

// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

function navbarBehaviour() {

    var navbar = document.querySelector("header .navbar.js--hideScroll");
    // Trovo l'altezza della navbar

    if (navbar) {
        var navbarHeight = navbar.offsetHeight;

        // Aggiungo l'altezza della navbar come padding all'elemento main
        var mainContent = document.querySelector('main.main-content');
        mainContent.style.paddingTop = navbarHeight + 'px';

        // Salvo la posizione dello scroll verticale
        var prevScrollpos = window.pageYOffset;
        var scrolledDown = false;

        // Evento scroll sull'oggetto window
        window.onscroll = function () {
            var currentScrollPos = window.pageYOffset;

            // Se l'user scrolla +200px si attiva l'effetto
            if (currentScrollPos > 200) {
                scrolledDown = true;
            } else {
                scrolledDown = false;
            }

            if (scrolledDown) {
                if (prevScrollpos > currentScrollPos) {
                    navbar.style.top = '0';
                } else {
                    navbar.style.top = '-' + navbarHeight + 'px';
                }
            }

            prevScrollpos = currentScrollPos;
        };
    }

}

navbarBehaviour();


jQuery(function ($) {

    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        //>=, not <=
        if (scroll >= 150) {
            //clearHeader, not clearheader - caps H
            $("body").addClass("is-scrolled");
        } else {
            $("body").removeClass("is-scrolled");
        }
    }); //missing );

    $('.animation-canvas img').each(function() {
        $(this).on('animationend', function() {
            $(this).removeClass('drop-in');
        });
    });

    $(window).scroll(function() {
        var scrollPos = $(this).scrollTop();

        const scrollVal = 100;

        if (scrollPos > scrollVal) {
            // Anima le immagini in base alla posizione di scroll
            $('.img-anime-1').css({
                'transform': 'translateX(' + (scrollPos - scrollVal) / 5 + 'px)'
            });

            $('.img-anime-2').css({
                'transform': 'translateX(' + (scrollPos - scrollVal) / 7.5 + 'px)'
            });

            $('.img-anime-3').css({
                'transform': 'translateX(' + -(scrollPos - scrollVal) / 10 + 'px)'
            });

            $('.img-anime-4').css({
                'transform': 'translateX(' + (scrollPos - scrollVal) / 12.5 + 'px)'
            });

            $('.img-anime-5').css({
                'transform': 'translateX(' + -(scrollPos - scrollVal) / 15 + 'px)'
            });

            $('.img-anime-6').css({
                'transform': 'translateX(' + (scrollPos - scrollVal) / 17.5 + 'px)'
            });

            $('.img-anime-7').css({
                'transform': 'translateX(' + -(scrollPos - scrollVal) / 20 + 'px)'
            });

            $('.img-anime-8').css({
                'transform': 'translateX(' + (scrollPos - scrollVal) / 22.5 + 'px)'
            });

            $('.img-anime-9').css({
                'transform': 'translateX(' + -(scrollPos - scrollVal) / 25 + 'px)'
            });

            $('.img-anime-10').css({
                'transform': 'translateX(' + (scrollPos - scrollVal) / 27.5 + 'px)'
            });

            $('.img-anime-11').css({
                'transform': 'translateX(' + -(scrollPos - scrollVal) / 30 + 'px)'
            });

            $('.img-anime-12').css({
                'transform': 'translateX(' + (scrollPos - scrollVal) / 32.5 + 'px)'
            });

            $('.img-anime-13').css({
                'transform': 'translateX(' + -(scrollPos - scrollVal) / 35 + 'px)'
            });

            $('.img-anime-14').css({
                'transform': 'translateX(' + (scrollPos - scrollVal) / 37.5 + 'px)'
            });

            $('.img-anime-15').css({
                'transform': 'translateX(' + -(scrollPos - scrollVal) / 40 + 'px)'
            });
        } else {
            // Ripristina la posizione originale degli elementi
            $('.img-anime-1, .img-anime-2, .img-anime-3, .img-anime-4, .img-anime-5, .img-anime-6, .img-anime-7, .img-anime-8, .img-anime-9, .img-anime-10, .img-anime-11, .img-anime-12, .img-anime-13, .img-anime-14, .img-anime-15').css({
                'transform': 'translateX(0px)'
            });
        }

    });


}); // jQuery End


const swiper_default = new Swiper('.swiper--default', {
    loop: false,
    // autoplay: {
    //     delay: 3000,
    // },
    spaceBetween: 0,
    speed: 600,
    slidesPerView: 1,
});